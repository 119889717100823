<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  b-card.shadow-none.border-0.mt-2
    b-tabs(:value="currentTab" @input="changeCurrentTab" pills, nav-class="bg-white", active-tab-class="", nav-wrapper-class="", content-class="")
      b-tab(title="總覽", active, title-item-class="mb-2")
        .card
          .title-box
            TimePeriodTab(:tab="statusTab" @tab-change="changeStatusBar")
        .row
          .col-lg-12
            .card.mb-2
              .card-body
                h4.header-title 最近訪問
                div.flex-grow-1.d-flex
                  apexchart.mx-auto(width='650' height='auto' :series='lineChartOption.series' :options='lineChartOption')
          .col-lg-12
            .card.mb-2
              .card-body
                h4.header-title 訪問總覽
                .row
                  .col-6
                    div.d-flex.align-items-center
                      apexchart(width='140' height='54' :series='smallChartOption.series' :options='smallChartOption')
                      span.ml-2 675,379 訪問數，662,720 不重複訪客
                      .ml-1.text-success.font-weight-semibold.d-flex.align-items-center
                        i.ri-arrow-up-fill
                        span +44%
                  .col-6
                    div.d-flex.align-items-center
                      apexchart(width='140' height='54' :series='smallChartOption.series' :options='smallChartOption')
                      span.ml-2 1 分 45 秒平均停留時間
                      .ml-1.text-danger.font-weight-semibold.d-flex.align-items-center
                        i.ri-arrow-down-fill
                        span -3.7%
                
                  .col-6
                    div.d-flex.align-items-center
                      apexchart(width='140' height='54' :series='smallChartOption.series' :options='smallChartOption')
                      span.ml-2 675,379 訪問數，662,720 不重複訪客
                      .ml-1.text-success.font-weight-semibold.d-flex.align-items-center
                        i.ri-arrow-up-fill
                        span +44%
                  .col-6
                    div.d-flex.align-items-center
                      apexchart(width='140' height='54' :series='smallChartOption.series' :options='smallChartOption')
                      span.ml-2 1 分 45 秒平均停留時間
                      .ml-1.text-danger.font-weight-semibold.d-flex.align-items-center
                        i.ri-arrow-down-fill
                        span -3.7%

                  .col-6
                    div.d-flex.align-items-center
                      apexchart(width='140' height='54' :series='smallChartOption.series' :options='smallChartOption')
                      span.ml-2 675,379 訪問數，662,720 不重複訪客
                      .ml-1.text-success.font-weight-semibold.d-flex.align-items-center
                        i.ri-arrow-up-fill
                        span +44%
                  .col-6
                    div.d-flex.align-items-center
                      apexchart(width='140' height='54' :series='smallChartOption.series' :options='smallChartOption')
                      span.ml-2 1 分 45 秒平均停留時間
                      .ml-1.text-danger.font-weight-semibold.d-flex.align-items-center
                        i.ri-arrow-down-fill
                        span -3.7%
                    

      b-tab(title="網頁", title-item-class="mb-2")
        .card
          .title-box
            TimePeriodTab(:tab="statusTab" @tab-change="changeStatusBar")
        .row 
          .col-lg-12
            .card
              TrafficSiteTable(:items="siteData")

      b-tab(title="訪客", title-item-class="mb-2")
        .card
          .title-box
            TimePeriodTab(:tab="statusTab" @tab-change="changeStatusBar")
        .row
          .col-lg-12
            .card.mb-2
              .card-body.d-flex.align-items-center.justify-content-between
                h4.header-title 地區來源
                div.flex-grow-1.d-flex
                  apexchart.mx-auto(width='650' height='auto' :series='barChartOption.series' :options='barChartOption')
          .col-lg-12
            .card
              .table-responsive.mb-0
                b-table(
                  responsive, 
                  ref="dataTable",
                  :items="getVisitorData", 
                  :fields="visitorFields", 
                  :per-page="perPage", 
                  :current-page="currentPage", 
                )
                  //- region
                  template(v-slot:cell(region)="row") {{ row.item.region }}

                  //- region
                  template(v-slot:cell(uv)="row") {{ $root.common.formatNumber(row.item.uv, 0) }}

                  //- PV佔比
                  template(v-slot:cell(percentage)="row") {{ row.item.percentage }}%
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import TrafficSiteTable from './components/TrafficSiteTable'
import TimePeriodTab from "./components/TimePeriodTab";

const mockData = [
  { region: '未知', uv: 418206, percentage: 12 },
  { region: '台灣', uv: 182789, percentage: 11 },
  { region: '美國', uv: 40132, percentage: 8 },
  { region: '日本', uv: 3124, percentage: 4 },
  { region: '韓國', uv: 1006, percentage: 2 }
]

const mockSiteData = [
  { site: 'news', pv: 654137, uv: 520106, bounce: 71, avgtop: '00:01:04', exit: 78, speed: 14.61,
    child: [
      { site: 'items', pv: 654137, uv: 520106, bounce: 71, avgtop: '00:01:04', exit: 78, speed: 14.61,
        child: [
          { site: '/ogxwZ19JaG', pv: 654137, uv: 520106, bounce: 71, avgtop: '00:01:04', exit: 78, speed: 14.61, url: 'https://ctinews.com/news/items/ogxwZ19JaG' },
          { site: 'JmxNPdQ5W8', pv: 654137, uv: 520106, bounce: 71, avgtop: '00:01:04', exit: 78, speed: 14.61, url: 'https://ctinews.com/news/items/JmxNPdQ5W8' }
        ],
        _showDetails: false
      },
      { site: 'topics', pv: 654137, uv: 520106, bounce: 71, avgtop: '00:01:04', exit: 78, speed: 14.61,
        child: [
          { site: '/ogxwZ19JaG', pv: 654137, uv: 520106, bounce: 71, avgtop: '00:01:04', exit: 78, speed: 14.61, url: 'https://ctinews.com/news/items/ogxwZ19JaG' },
          { site: 'JmxNPdQ5W8', pv: 654137, uv: 520106, bounce: 71, avgtop: '00:01:04', exit: 78, speed: 14.61, url: 'https://ctinews.com/news/items/JmxNPdQ5W8' }
        ],
        _showDetails: false
      }
    ],
    _showDetails: true
  },
  { site: '/index', pv: 654137, uv: 520106, bounce: 71, avgtop: '00:01:04', exit: 78, speed: 14.61, url: 'https://ctinews.com/' },
  { site: '/live', pv: 654137, uv: 520106, bounce: 71, avgtop: '00:01:04', exit: 78, speed: 14.61, url: 'https://ctinews.com/live' },
  { site: '/videos', pv: 654137, uv: 520106, bounce: 71, avgtop: '00:01:04', exit: 78, speed: 14.61,
    child: [
      { site: '/XYwjNZYzwv', pv: 654137, uv: 520106, bounce: 71, avgtop: '00:01:04', exit: 78, speed: 14.61, url: 'https://ctinews.com/videos/XYwjNZYzwv'},
      { site: '/vVbD0jQLbZ', pv: 654137, uv: 520106, bounce: 71, avgtop: '00:01:04', exit: 78, speed: 14.61, url: 'https://ctinews.com/videos/vVbD0jQLbZ'},
      { site: '/yp3dn9x7bZ', pv: 654137, uv: 520106, bounce: 71, avgtop: '00:01:04', exit: 78, speed: 14.61, url: 'https://ctinews.com/videos/yp3dn9x7bZ'}
    ],
    _showDetails: false
  },
]

/**
 * Starter component
 */
export default {
  name: 'TrafficAnalysis',
  data () {
    return {
      title: "流量數據查詢 / 數據分析",
      items: [{
        text: "數據分析",
        href: "/dashboard",
      },
      {
        text: "流量數據查詢",
        active: true,
      }
      ],
      tableData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      filter: null,
      filterOn: [],
      sortDesc: false,
      status: false,
      statusTab: 0,
      currentTab: 0,
      searchData: {
        unit: [],
        type: [],
        source: [], 
        editer: ''
      },
      editer: [],
      currentEditer: false,
      lineChartOption: {
        chart: {
          height: 350,
          type: "line"
        },
        dataLabels: {
          enabled: false
        },
        series: [
          {
            name: "Series 1",
            data: [
              [1486684800000, 347825], // x: Timestamps, y: values
              [1486771200000, 432189], 
              [1486857600000, 319382] , 
              [1486944000000, 435678], 
              [1487030400000, 330212], 
              [1487116800000, 528763]
            ]
          }
        ],
        xaxis: {
          type: 'datetime'
        },
        colors: ["#247BA0"],
        plotOptions: {
        },
        stroke: {
          curve: "smooth"
        },
        markers: {
          size: 6,
        }
      },
      smallChartOption: {
        chart: {
          type: 'area',
          sparkline: {
            enabled: true,
          },
        },
        series: [{
          data: [12, 14, 2, 47, 42, 15, 47, 75, 65, 19, 14],
        }],
        grid: {
          padding: {
            top: 10,
          },
        },
        tooltip: {
          fixed: {
            enabled: false,
          },
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: (seriesName) => {
                return "";
              },
            },
          },
          marker: {
            show: false,
          },
        },
        stroke: {
          show: true,
          curve: "smooth",
          width: 1,
        }
      },
      barChartOption: {
        chart: {
          height: 350,
          type: "bar"
        },
        dataLabels: {
          enabled: false
        },
        series: [{
          name: '台灣',
          data: [125,0,0,0,0]
        },
          {
          name: '中國',
          data: [0,40,0,0,0]
        },
          {
          name: '歐美地區',
          data: [0,0,60,0,0]
        },
                {
          name: '日本',
          data: [0,0,0,90,0]
        },
                {
          name: '韓國',
          data: [0,0,0,0,70]
        }
                
        ],
        colors: ["#FF1654", "#247BA0", "#e87613", "#2f8f27", "#5f1eba"],
        plotOptions: {
          bar: {
            columnWidth: "50%"
          }
        },
        xaxis: {
          categories: ['台灣', '中國', '歐美地區', '日本', '韓國']
        },
        legend: {
          position: "right",
          offsetY: 40
        }
      },
      visitorFields: [
        { key: 'region', label: "地區", class: 'width-auto start' },
        { key: "uv", label: "不重複訪客", class: 'width-auto end' },
        { key: "percentage", label: "佔比", class: 'width-auto center' },
      ],
      siteData: []
    };
  },
  methods: {
    // 變更狀態夾
    changeStatusBar (_status) {
      this.statusTab = _status
      this.currentPage = 1
      this.reloadData()
    },
    changeCurrentTab(_tab) {
      this.currentTab = _tab
      this.currentPage = 1
      this.reloadData()
    },
    reloadData () {
      if (this.currentTab === 1) {
        this.getSiteData()
      } else if (this.currentTab === 2) {
        this.$refs.dataTable.refresh()
      }
    },
    // 獲得網頁清單
    getSiteData () {
      let vm = this
      console.log('get site')
      vm.siteData = mockSiteData
      // this.$root.apis.getData({
      //   status_type: this.statusTab,
      //   page: this.currentPage
      // }, function (_response) {
      //   let response = _response.body.data
      //   vm.currentPage = response.page
      //   vm.totalRows = response.total
      //   vm.perPage = response.per_page
      //   _callback(response.items)
      // })
    },
    // 獲得訪客清單
    getVisitorData (_table, _callback) {
      let vm = this
      console.log('get visitor')
      _callback(mockData)      
      // this.$root.apis.getData({
      //   status_type: this.statusTab,
      //   page: this.currentPage
      // }, function (_response) {
      //   let response = _response.body.data
      //   vm.currentPage = response.page
      //   vm.totalRows = response.total
      //   vm.perPage = response.per_page
      //   _callback(response.items)
      // })
    }
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
    TrafficSiteTable,
    TimePeriodTab
  }
};
</script>

<style lang="sass" scoped>
.card-header
  background: #fff
.label-select
  border: none
  padding: 0
  height: auto
  line-height: 1
  font-weight: 600
.header-row
  display: flex
  align-items: center
  .header-title
    margin: 0
    flex: 1
.tab-group
  background: rgba(229,231,235,1)
  padding: 0.5rem 1rem
  button.off
    color: rgba(156,163,175,1)    
    background: rgba(0,0,0,0)
    border-color: rgba(0,0,0,0)
::v-deep .width-auto
  min-width: 7rem
  white-space: nowrap
  vertical-align: middle
  &.center
    text-align: center
  &.end
    text-align: end
  &.start
    text-align: start

::v-deep .table-btn
  text-decoration: underline
  color: #0073b8
  background: none
  border: none
  padding: 0
  font-size: 14px
  &.delete
    color: #333333
  
  
</style>
