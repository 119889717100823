<template lang="pug">
.table-responsive.mb-0
  b-table(
    responsive, 
    ref="dataTable",
    :items="items", 
    :fields="siteFields", 
    :class="isChild  ? 'is-child' : ''"
  )
    //- 網頁網址
    template(v-slot:cell(site)="row")
      a.d-flex.align-items-center(v-if="row.item.url" :href="row.item.url" target="_blank" rel="noopener noreferrer") 
        i.mr-1.text-muted.ri-external-link-line
        span.table-btn {{  row.item.site }}
      b-button(v-else variant="link" size="sm" @click="row.toggleDetails" class="p-0")
        .d-flex.align-items-center(v-if="!row.item._showDetails")
          i.mr-1.text-muted.ri-add-box-line
          span.text-dark {{  row.item.site }}
        .d-flex.align-items-center(v-else)
          i.mr-1.text-muted.ri-checkbox-indeterminate-line
          span.text-dark {{  row.item.site }}

    template(v-slot:row-details="row")
      //- Table
      TrafficSiteTable(:items="row.item.child" isChild)

    //- 瀏覽數
    template(v-slot:cell(pv)="row") {{ $root.common.formatNumber(row.item.pv, 0) }}

    //- 不重複瀏覽數
    template(v-slot:cell(uv)="row") {{ $root.common.formatNumber(row.item.uv, 0) }}
    
    //- 跳出率
    template(v-slot:cell(bounce)="row") {{ row.item.bounce }}%

    //- 網頁平均停留時間
    template(v-slot:cell(avgtop)="row") {{ row.item.avgtop }}
    
    //- 離開率
    template(v-slot:cell(exit)="row") {{ row.item.exit }}%

    //- 頁面平均載入時間
    template(v-slot:cell(speed)="row") {{ row.item.speed }} 秒

</template>

<script>
import TrafficSiteTable from "./TrafficSiteTable.vue"

export default {
  name: 'TrafficSiteTable',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    isChild: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      siteFields: [
        { key: 'site', label: "網頁網址", class: 'width-auto start' },
        { key: "pv", label: "瀏覽數", class: 'width-auto end' },
        { key: "uv", label: "不重複瀏覽數", class: 'width-auto end' },
        { key: "bounce", label: "跳出率", class: 'width-auto center' },
        { key: "avgtop", label: "網頁平均停留時間", class: 'width-auto center' },
        { key: "exit", label: "離開率", class: 'width-auto center' },
        { key: "speed", label: "頁面平均載入時間", class: 'width-auto center' },
      ],
      siteDate: []
    }
  },
  components: {
    TrafficSiteTable
  }
}
</script>

<style lang="sass" scoped>
::v-deep .is-child
  .b-table thead 
    display: none
</style>
